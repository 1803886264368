/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import { Button } from '@ux/balance-react';
import { useEffect, useRef, useState } from 'react';
import dateformat from "dateformat";
import { useNavigate } from 'react-router-dom-v6'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { DailyExceptionDetailRes, exception_reportDate, exception_transaction_ID, ExceptionTransactionCurrentPage, ExceptionTransactionForcedPage, exception_batch_ID, userState, selected_corp_code, ExceptionBatchDetailRes, exception_lockbox_code } from '../atom';
import { dailyExceptionDetailData, DailyDecisionDetailRes } from '../API_Integrations/exceptionDailyDetail';
import { exceptionBatchDetailData, SelectBatchDetailForLockbox } from '../API_Integrations/exceptionBatchDetail';
import ClientHeader from '../components/ClientHeader';
import Loader from '../components/Loader';
import ModalsContainer from '../components/ModalsContainer';
import CustomModal from '../components/CustomModal';
import Table from '../components/Table';
import { useFocusOnElemnt } from '../utils/use-focus-on-element';
import { useTitle } from '../utils/use-title';
import DateDisplay from '../components/DateDisplay';
import LockboxesDailyDetailReport from '../API_Integrations/LockboxDailyDetailReport';
import ExceptionMirror from '../components/ExceptionMirror';

function ExceptionDailyDettailReport() {
    useTitle("Exception daily decision detail report");

    const [loading, setLoading] = useState(false);
    const [data_daily_detail_report, setData_daily_detail] = useRecoilState(DailyExceptionDetailRes);
    const setException_lockboxCode = useSetRecoilState(exception_lockbox_code);
    const setTransaction_current_page = useSetRecoilState(ExceptionTransactionCurrentPage);
    const setTransaction_forced_page = useSetRecoilState(ExceptionTransactionForcedPage);
    const setTransaction_ID = useSetRecoilState(exception_transaction_ID);
    const setException_batchID = useSetRecoilState(exception_batch_ID);
    const setSelected_corpCode = useSetRecoilState(selected_corp_code);
    const setdata_batch_detail = useSetRecoilState(ExceptionBatchDetailRes);
    const reportDate = useRecoilValue(exception_reportDate);
    const user = useRecoilValue(userState);
    const [formattedReportDate, setFormattedReportDate] = useState('');
    const [modalIsOpenDownload, setModalIsOpenDownload] = useState(false);
    const initialTextRef = useRef<HTMLHeadingElement | null>(null);
    const navigate = useNavigate();

    useFocusOnElemnt(initialTextRef);

    useEffect(() => {
        const date = dateformat(reportDate, "yyyy-mm-dd");
        setFormattedReportDate(dateformat(reportDate, "mm/dd/yyyy"));
        getDailySummaryData(date);
    }, []);

    const getDailySummaryData = async (date: string) => {
        setLoading(true);
        let result = await dailyExceptionDetailData(date);
        const sortedResult = sortEscalated(result);
        setData_daily_detail(sortedResult);
        setLoading(false);
    }

    const handleDownloadReports = () => {
        handleCSV();
        setModalIsOpenDownload(true);
    }

    const sortEscalated = (data: DailyDecisionDetailRes[]): DailyDecisionDetailRes[] => {
        const myName = `${user.firstName} ${user.lastName}`;
        return data.sort(({ status: a, escalatedUserName: aName }, { status: b, escalatedUserName: bName }) => {
            if (a === 'Escalated' && aName === myName) return -1;
            if (b === 'Escalated' && bName === myName) return 1;
            if (a === 'Escalated') return -1;
            if (b === 'Escalated') return 1;
            return 0;
        });
    }

    const handleTransactionSelection = async (row: any) => {
        const sequenceNumber = row.sequenceNumber;
        const date = dateformat(reportDate, "yyyy-mm-dd");
        if (sequenceNumber) {
            setException_batchID(0);
            setSelected_corpCode('All');
            let body: SelectBatchDetailForLockbox = {
            };
            body.processingDate = date;
            body.exceptionBatchId = row.exceptionBatchId;
            let result = await exceptionBatchDetailData(row.lockboxCode, body);
            setdata_batch_detail(result);
            setException_lockboxCode(row.lockboxCode);
            setTransaction_current_page(sequenceNumber - 1);
            setTransaction_forced_page(undefined);
            setTransaction_ID(row.transactionId);
            setException_batchID(row.exceptionBatchId);
            navigate(`/exception-transaction-detail/${row.transactionId}`);
        }

    }

    const handleCSV = () => {
        LockboxesDailyDetailReport.createReport(formattedReportDate);
    }

return (
        <main>
            <ClientHeader showClientName={true} showExceptionsBreadcrumbs={true} />
            <div className='loader-section'>
                {!loading ?
                    <>
                    {(data_daily_detail_report && data_daily_detail_report.length > 0) ?
                        <>
                            <ExceptionMirror
                                dailyDecisionDetail={true}
                                date={formattedReportDate}                               
                            />
                                <ModalsContainer>
                                    <CustomModal
                                        downloadIconButton={true}
                                        showClose={true}
                                        ButtonTitle='Download report as CSV'
                                        ModalIsOpen={modalIsOpenDownload}
                                        OpenModal={handleDownloadReports}
                                        CloseModal={() => { setModalIsOpenDownload(false) }}
                                        ModalText='Your report has been requested and will be available in Reports.'
                                        ModalTitle='Request submitted'
                                    />
                                </ModalsContainer>
                                <div className='daily-summary-table'>
                                    <Table
                                    ariaDescribedBy={null}
                                    caption={`Daily decision summary table for ${formattedReportDate}`}
                                    classes=""
                                    columnData={
                                        [
                                            {
                                                rowCellTableRowComponentsArrayIndex: 0,
                                                headerId: 'sequenceNumber',
                                                type: 'component',
                                                headerLabel: 'Transaction ID'
                                            },
                                            {
                                                headerId: 'lockboxCode',
                                                headerLabel: 'Box ID'
                                            },
                                            {
                                                headerId: 'siteCode',
                                                headerLabel: 'Site ID'
                                            },
                                            {
                                                headerId: 'exceptionBatchNumber',
                                                headerLabel: 'Batch Number'
                                            },
                                            {
                                                headerId: 'assignedUser',
                                                headerLabel: 'User'
                                            },
                                            {
                                                rowCellTableRowComponentsArrayIndex: 1,
                                                headerId: 'lastUpdated',
                                                type: 'component',
                                                headerLabel: 'Last update'
                                            },
                                            {
                                                headerId: 'checkAmountFormatted',
                                                headerLabel: 'Check amount'
                                            },
                                            {
                                                headerId: 'documentAccountNumber',
                                                headerLabel: 'DDA no.'
                                            },
                                            {
                                                headerId: 'routingNumber',
                                                headerLabel: 'Routing and Transit'
                                            },
                                            {
                                                headerId: 'serialNumber',
                                                headerLabel: 'Serial no.'
                                            },
                                            {
                                                rowCellTableRowComponentsArrayIndex: 2,
                                                type: 'component',
                                                headerId: 'status',
                                                headerLabel: 'Status'
                                            },
                                            {
                                                headerId: 'accountNumber',
                                                headerLabel: 'Account no.'
                                            },
                                            {
                                                headerId: 'documentMiscellaneous1',
                                                headerLabel: 'Misc1.'
                                            },
                                            {
                                                headerId: 'documentMiscellaneous2',
                                                headerLabel: 'Misc2'
                                            },
                                            {
                                                headerId: 'corpCode',
                                                headerLabel: 'Corp Code'
                                            },
                                            {
                                                headerId: 'documentAmountFormatted',
                                                headerLabel: 'Doc amount'
                                            },
                                            {
                                                headerId: 'comment',
                                                headerLabel: 'Comment'
                                            },
                                        ]
                                    }
                                    data={data_daily_detail_report}
                                    tableRowComponents={[
                                        (props: any) => (
                                            <Button
                                                text={props.row.original.sequenceNumber}
                                                clickMethod={() => handleTransactionSelection(props.row.original)}
                                                variant="text-primary"
                                                classes="underlined-links"
                                            />
                                        ),
                                        (props: any) => (
                                            <DateDisplay date={props.row.original.lastUpdated} format="paddedShortDateTime" />
                                        ),
                                        (props: any) => (
                                            <span>{(props.row.original.escalatedUserName && props.row.original.status === "Escalated") ? `Escalated to ${props.row.original.escalatedUserName}` : props.row.original.status}</span>
                                        )
                                    ]}
                                    />
                                </div>
                            </>
                            :
                            <></>
                        }
                    </>
                    :
                    <Loader />
                }
            </div>
        </main>);
}

export default ExceptionDailyDettailReport;

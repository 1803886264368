import requests from "./apiRequest";
import { ILockbox_req_body } from "../interfaces/iLockBox";

const Lockbox = {
    listClientLockbox: async (body: ILockbox_req_body) => {
        try {
            let result = await requests.getParams('lockboxes/client', body);
            return result;
        } catch (error) {
            console.log('error from lockbox api integration: ', error);
        }
    },
    listLockbox: async (body: ILockbox_req_body) => {
        try {
            let result = await requests.getParams('lockboxes', body);
            return result;
        } catch (error) {
            console.log('error from lockbox api integration: ', error);
        }
    },
    getFavorites: async () => {
        try {
            let result = await requests.get('lockboxes/favorites');
            return result;
        } catch (error) {
            console.log('error from lockbox favorites api integration: ', error);
        }
    },
    addFavorite: async (ID: number) => {
        try {
            await requests.postParams(`lockboxes/${ID}/favorite`);
        } catch (error) {
            console.log('error from lockbox addFavorite api integration: ', error);
        }
    },
    deleteFavorite: async (ID: number) => {
        try {
            await requests.delete(`lockboxes/${ID}/favorite`);
        } catch (error) {
            console.log('error from lockbox deleteFavorite api integration: ', error);
        }
    },

    getFullLockbox: async (ID: string) => {
        try {
            let result = await requests.get(`lockboxes/${ID}`);
            return result;
        } catch (error) {
            console.log('error from lockbox full api integration: ', error);
        }
    }


}
export default Lockbox;

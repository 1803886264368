/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom-v6';
import dateformat from "dateformat";
// @ts-ignore
import { Checkbox, Select, Button, DropdownMenu, DropdownTrigger, DropdownMenuContent, } from '@ux/balance-react';
// @ts-ignore
import { IconSearch } from '@ux/balance-icons';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ExceptionBatchDetailRes, exception_lockbox_code, exception_summary_processDate, exception_batch_ID, selected_corp_code, ExceptionTransactionCurrentPage, exception_transaction_ID, ExceptionTransactionForcedPage, userState } from '../atom';
import { SelectBatchDetail, exceptionBatchDetailData, SelectBatchDetailForLockbox, generateExceptionBatchDetailReport, ExceptionBatchesRes } from '../API_Integrations/exceptionBatchDetail';
import CorpCodes from '../API_Integrations/CorpCodes';
import { ICorpCodes } from '../interfaces/ICorpCodes';
import ClientHeader from '../components/ClientHeader';
import Table from '../components/Table';
import Loader from '../components/Loader';
import ModalsContainer from '../components/ModalsContainer';
import CustomModal from '../components/CustomModal';
import ExceptionMirror from '../components/ExceptionMirror';
import { useTitle } from '../utils/use-title';
import CurrencyDisplay from '../components/CurrencyDisplay';
import Lockbox from '../API_Integrations/Lockbox';
import { all } from 'axios';

function ExceptionBatchDetail() {
    useTitle("Exception batch detail");
    const [data_batch_detail, setdata_batch_detail] = useRecoilState(ExceptionBatchDetailRes);
    const [exception_batchID, setException_batchID] = useRecoilState(exception_batch_ID);
    const [selected_corpCode, setSelected_corpCode] = useRecoilState(selected_corp_code);
    const exception_lockboxCode = useRecoilValue(exception_lockbox_code);
    const processDate = useRecoilValue(exception_summary_processDate);
    const setTransaction_current_page = useSetRecoilState(ExceptionTransactionCurrentPage);
    const setTransaction_forced_page = useSetRecoilState(ExceptionTransactionForcedPage);
    const setTransaction_ID = useSetRecoilState(exception_transaction_ID);
    const user = useRecoilValue(userState);
    const [data_corpCodes, setData_corpCodes] = useState<ICorpCodes[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectSearchBatch, setSelectSearchBatch] = useState<SelectBatchDetail>({ batchId: 0, batchNumber: '', corpCode: '' });
    const [batch_options, setBatch_options] = useState<{ label: string; value: number | string; }[]>([]);
    const [corpCode_options, setCorpCode_options] = useState<{ label: string, value: string; }[]>([]);
    const [formattedProcessDate, setFormattedProcessDate] = useState('');
    const [newBatchSearch, setNewBatchSearch] = useState(false);
    const [showLockedTransactionModal, setShowLockedTransactionModal] = useState({ show: false, userName: '' });
    const [selectAllFilters, setSelectAllFilters] = useState(true);
    const [filterstatus, setfilterstatus] = useState([true, true, true, true, true]);
    const [modalIsOpenDownload, setModalIsOpenDownload] = useState(false);
    const [setModalIsOpenViewReport] = useState(false);
    const filterOptions = ['Pending', 'Completed', 'Returned', 'Rejected', 'Escalated'];
    const allBatchOption = [{
        label: 'All',
        value: 0
    }];
    const [isLockboxDistributor, setIsLockboxDistributor] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
    }, [data_batch_detail]);

    useEffect(() => {
    }, [exception_batchID]);

    useEffect(() => {
        
        if (!exception_lockboxCode) return;

        
        const fetchAllData = async () => {
            try {
                
                const lockbox = await Lockbox.getFullLockbox(exception_lockboxCode);
                const distributorStatus = lockbox?.isLockboxDistributor ?? false;
                setIsLockboxDistributor(distributorStatus);

                await getCorpCodesOptions(distributorStatus);

                setFormattedProcessDate(dateformat(processDate, "mm/dd/yyyy"));

                await getBatchDetailData(exception_batchID, selected_corpCode);

            } catch (error) {
                console.error("[ERROR] fetchAllData:", error);
            }
        };

        fetchAllData();
    }, [
        exception_lockboxCode,
        exception_batchID,
        selected_corpCode,
        processDate
    ]);

    useEffect(() => {
        if (newBatchSearch) {
            const handleBrowserHistory = () => {
                const currentPath = window.location.pathname;
                const pathId = currentPath.replace('/exception-batch-detail/', '');
                const idNumber = parseInt(pathId, 10);
                setException_batchID(idNumber);
            }
            window.onpopstate = handleBrowserHistory;
            return () => {
                window.onpopstate = null;
            }
        }
    }, [newBatchSearch]);

    useEffect(() => {
    }, [selectAllFilters, filterstatus]);

    

    const handleOnChangeFilterSelectAll = () => {
        const newVal = !selectAllFilters;
        setSelectAllFilters(newVal);
        setfilterstatus(newVal ? [true, true, true, true, true] : [false, false, false, false, false]);
    };

    const handleOnChangeFilterStatus = (i: number) => {
        const newFilterStatus = [...filterstatus];
        newFilterStatus[i] = !newFilterStatus[i];
        setfilterstatus(newFilterStatus);
        setSelectAllFilters(newFilterStatus.every((filter) => filter === true))
    };

    const getBatchDetailData = async (batch: number, corp?: string) => {
        setLoading(true);
        const date = dateformat(processDate, "yyyy-mm-dd");
        let body: SelectBatchDetailForLockbox = {
        };
        body.processingDate = date;
        if (corp !== 'All' && data_corpCodes.length > 0) {
            body.corpCode = corp;
        }
        if (batch !== 0) {
            body.exceptionBatchId = batch;
        }

        let result = await exceptionBatchDetailData(exception_lockboxCode, body);
        if (result !== undefined) {
            if (!('corpCode' in result) && !('corpCodeName' in result)) {
                setSelectSearchBatch({ ...selectSearchBatch, batchId: 0 });
            }
            let BatchOptions = result.batches.map((el) => ({
                label: String(el.batchNumber),
                value: String(el.batchId)
            }));
            if (BatchOptions.length > 1) {
                setBatch_options([...allBatchOption, ...BatchOptions]);
                setSelectSearchBatch({ ...selectSearchBatch, batchId: exception_batchID });

            } else if (BatchOptions.length === 1) {
                setBatch_options([...BatchOptions]);
                setSelectSearchBatch({ ...selectSearchBatch, batchId: exception_batchID });
            }
            const sortedResult = sortEscalated(result);
            setdata_batch_detail(sortedResult);
            setLoading(false);
        } else {
            console.log('items not found');
        }
        setLoading(false);
    }

    const getCorpCodesData = async () => {
        let result = await CorpCodes.list();
        if (result !== undefined) {
            setData_corpCodes(result);
            return result;
        } else {
            console.log('item not found')
        }
    }

    const sortEscalated = (data: ExceptionBatchesRes): ExceptionBatchesRes => {
        data.batchItems.sort(({ sequenceNumber: a }, { sequenceNumber: b }) => a - b);
        return data;
    }

    async function getCorpCodesOptions(distributorStatus: boolean) {

        const allCorpCodesOption = [
            { label: 'All', value: 'All' }
        ];

        try {
            const result = await getCorpCodesData();
            if (!result) return;

            let CorpCodesOptions = result.map((el: ICorpCodes) => (
                {
                    label: `${el.code} - ${el.name}`,
                    value: el.code
                }
            ));

            if (distributorStatus && !user.isAdmin) {
                const allowedCorpCodes = user.allowedCorpCodes ?? [];

                CorpCodesOptions = CorpCodesOptions.filter((option: { label: string; value: string }) =>
                    allowedCorpCodes.includes(option.value)
                );

                setCorpCode_options([...allCorpCodesOption, ...CorpCodesOptions]);

            } else {
                setCorpCode_options([...allCorpCodesOption, ...CorpCodesOptions]);
            }
        } catch (error) {
            console.error("[ERROR] Failed to fetch or process corp codes options:", error);
        }
    };


    const handleOnChangeSelectBatch = (event: Event) => { setSelectSearchBatch({ ...selectSearchBatch, batchId: Number((event.target as HTMLInputElement).value) }) }

    const handleOnChangeSelectCorpCode = (event: Event) => { setSelectSearchBatch({ ...selectSearchBatch, corpCode: (event.target as HTMLInputElement).value }) }

    const HandleSearchBatch = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        let searchBatch = selectSearchBatch.batchId;
        let searchCorpCode = selectSearchBatch.corpCode || '';
        setSelected_corpCode(searchCorpCode)
        if (searchBatch !== exception_batchID) {
            setException_batchID(searchBatch);
            setNewBatchSearch(true);
            navigate(`/exception-batch-detail/${searchBatch}`);
        } else {
            getBatchDetailData(exception_batchID, searchCorpCode);
        }
        setSelectAllFilters(true);
        setfilterstatus([true, true, true, true, true]);
    }
   

    const handleTransactionSelection = (row: any) => {
        // const sequenceNumber = row.sequenceNumber;
        if (!row || typeof row !== 'object') {
            console.error("[ERROR] Invalid row data:", row);
            return;
        }
        const { id } = row;
        if (!id) {
            console.error("[ERROR] Missing 'id' in row:", row);
            return;
        }
        try {
            
            const currentIndex = data_batch_detail.batchItems.findIndex(item => item.id === id);

            if (currentIndex === -1) {
                console.error("[ERROR] Selected row not found in batch items:", row);
                return;
            }

            // Update state based on the actual index in the current filtered array
            setTransaction_current_page(currentIndex);
            setTransaction_forced_page(undefined);
            setTransaction_ID(id);

            // Navigate to the transaction detail page
            navigate(`/exception-transaction-detail/${id}`);
        } catch (error) {
            console.error("[ERROR] Failed to handle transaction selection:", error);
        }


    }

    const handleDownloadReports = () => {
        const lockboxCode = exception_lockboxCode.toString();
        const processingDate = dateformat(processDate, "yyyy-mm-dd")
       var corpCode = data_batch_detail.corpCode
        if (corpCode === undefined)
        {
            corpCode = ""
        }
        generateExceptionBatchDetailReport({ lockboxCode: lockboxCode, processingDate: processingDate, fileType: 'Csv', corpCode: data_batch_detail.corpCode,  exceptionBatchId: exception_batchID })
        setModalIsOpenDownload(true);
    }

    return (
        <main>
            <ClientHeader showClientName={true} showExceptionsBreadcrumbs={true} />
            <div className='loader-section'>
                {!loading ?
                    <div className='batch-data-container'>

                        {data_batch_detail && data_corpCodes && <>
                            <ExceptionMirror
                                exceptionBatchDetail={true}
                                batchId={data_batch_detail.batchItems?.[0]?.batchNumber}
                                batchNumber={data_batch_detail.batchItems?.[0]?.batchNumber}
                                date={formattedProcessDate}
                                showCorpCode={data_corpCodes.length > 0}
                                total={data_batch_detail.totalExceptionsCount}
                                id={exception_lockboxCode}
                                outstanding={data_batch_detail.outstandingExceptionsCount}
                                completed={data_batch_detail.completedExceptionsCount}
                                returned={data_batch_detail.returnedExceptionsCount}
                                escalated={data_batch_detail.escalatedExceptionsCount}
                                rejected={data_batch_detail.rejectedExceptionsCount}
                                corpCode={data_batch_detail.corpCode + " " + data_batch_detail.corpCodeName}
                                allBatches={exception_batchID === 0}
                                allCorpCodes={!('corpCode' in data_batch_detail) && !('corpCodeName' in data_batch_detail)}
                            />

                            <form className="exception-search-control" onSubmit={HandleSearchBatch}>

                                <Select
                                    label="Select batch"
                                    name="Select batch"
                                    size='small'
                                    classes='width-208'
                                    options={batch_options}
                                    handleOnChange={handleOnChangeSelectBatch}
                                    value={selectSearchBatch.batchId}
                                />
                                {data_corpCodes.length > 0 && <Select
                                    label="Select Corp Code"
                                    name="Select Corp Code"
                                    size='small'
                                    classes='width-272 left-24-gap-filters'
                                    options={corpCode_options}
                                    handleOnChange={handleOnChangeSelectCorpCode}
                                    value={selectSearchBatch.corpCode}
                                />}
                                <div className='top-gap-32'>
                                    <Button
                                        classes="left-24-gap-filters"
                                        size="medium"
                                        text="Search"
                                        type="submit"
                                        customIcon={IconSearch}
                                        iconPosition='left'
                                    />
                                </div>
                            </form>
                            {data_batch_detail.batchItems && data_batch_detail.batchItems.length > 0 && <div className='exception-batch-detail-filter-container'>
                                
                                <DropdownMenu classes='exception-batch-detail-filter'>
                                    <DropdownTrigger
                                        text="Filter status by"
                                        ariaLabel="Filter status by menu"
                                        dataTestId="filter-status-menu"
                                        variant="alternate"
                                        size="medium"
                                        classes="highlighted-active"
                                    />
                                    <DropdownMenuContent>
                                        <p className='filter-status-label'>Filter transaction status</p>
                                        <div className='dropdown-divider'></div>
                                        <Checkbox
                                            isChecked={selectAllFilters}
                                            handleOnChange={handleOnChangeFilterSelectAll}
                                            label="Select all"
                                            size='small'
                                        />
                                        <div className='dropdown-divider'></div>
                                        <div className='checkbox-group'>
                                            {
                                                filterOptions.map((option: string, index: number) => (
                                                    <Checkbox
                                                        isChecked={filterstatus[index]}
                                                        handleOnChange={() => handleOnChangeFilterStatus(index)}
                                                        label={option}
                                                        size='small'
                                                        classes="filter-status-checkboxes"
                                                    />
                                                ))
                                            }
                                        </div>
                                    </DropdownMenuContent>
                                </DropdownMenu>    
                            </div>}
                          
                        </>

                        }
                        {(data_batch_detail.batchItems && data_batch_detail.batchItems.length > 0) ?
                            <>
                                <ModalsContainer>
                                    <CustomModal
                                        downloadIconButton={true}
                                        showClose={true}
                                        ButtonTitle='Download report as CSV'
                                        ModalIsOpen={modalIsOpenDownload}
                                        OpenModal={handleDownloadReports}
                                        CloseModal={() => { setModalIsOpenDownload(false) }}
                                        ModalText='Your report has been requested and will be available in Reports.'
                                        ModalTitle='Request submitted'
                                    />
                                </ModalsContainer>
                                <div >
                                    <Table
                                        ariaDescribedBy={null}
                                        caption={`Exception batch detail for ${formattedProcessDate}`}
                                        classes=""
                                        columnData={
                                            [
                                                {
                                                    rowCellTableRowComponentsArrayIndex: 1,
                                                    type: 'component',
                                                    headerId: 'status',
                                                    headerLabel: 'Transaction status '
                                                },
                                                {
                                                    rowCellTableRowComponentsArrayIndex: 0,
                                                    headerId: 'sequenceNumber',
                                                    type: 'component',
                                                    headerLabel: 'Transaction ID'
                                                },
                                                {
                                                    rowCellTableRowComponentsArrayIndex: 2,
                                                    headerId: 'amount',
                                                    type: 'component',
                                                    headerLabel: 'Amount '
                                                },
                                                {
                                                    headerId: 'lockedByUserName',
                                                    headerLabel: 'Locked by user '
                                                },

                                                {
                                                    headerId: 'exceptionReason',
                                                    headerLabel: 'Exception reason '
                                                },
                                            ]
                                        }
                                        data={data_batch_detail.batchItems.filter((batch) => {
                                            if(selectAllFilters) {
                                                return true;
                                            }
                                            return (filterstatus[0] && batch.status === 'Pending') ||
                                            (filterstatus[1] && batch.status === 'Completed') ||
                                            (filterstatus[2] && batch.status === 'Returned') ||
                                            (filterstatus[3] && batch.status === 'Rejected') ||
                                            (filterstatus[4] && batch.status === 'Escalated');
                                        })}
                                        tableRowComponents={[
                                            (props: any) => (
                                                    <Button
                                                        variant="text-primary"
                                                        text={props.row.original.sequenceNumber}
                                                        clickMethod={() => handleTransactionSelection(props.row.original)}
                                                        classes="handle-lockbox-selection underlined-links"
                                                        dataTestId={props.row.original.id}
                                                    />
                                            ),
                                            (props: any) => (
                                                <span>{(props.row.original.escalatedUserName && props.row.original.status === "Escalated") ? `Escalated to ${props.row.original.escalatedUserName}` : props.row.original.status}</span>
                                            ),
                                            (props: any) => (
                                                <CurrencyDisplay currency={props.row.original.amount} />
                                            )
                                        ]}
                                    />
                                </div>
                                <ModalsContainer>
                                    <CustomModal
                                        okType
                                        ModalText={`This transaction is locked because ${showLockedTransactionModal.userName} is using it so you cannot access it now.`}
                                        ModalTitle='Transaction is currently locked'
                                        ModalIsOpen={showLockedTransactionModal.show}
                                        CloseModal={() => { setShowLockedTransactionModal({ ...showLockedTransactionModal, show: false }) }} />
                                </ModalsContainer>
                            </>
                            :
                            <p className='zero-state'>No result found.</p>
                        }
                    </div>
                    :
                    <Loader />
                }
            </div>
        </main>
    )
}

export default ExceptionBatchDetail;
